/* ----------------------------------------------------
   three js related scene
---------------------------------------------------- */

/* Disable resizing of the window */
html,
body {
  max-width: 100%;
  overflow: hidden; /* prevent scrollers */
  position: fixed;
}

/* Disable moving of the window */
canvas {
  touch-action: none;
  top: 0;
  left: 0;
}

/* Make sure the canvas covers the entire viewport */
canvas {
  width: 100vw; /* Set the width to 100% of the viewport width */
  height: 100vh;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: rgba(46, 46, 46, 1);
  margin: 0;
  padding: 0;
}

#ingresar {
  display: none;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#intro {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw; /* Set the width to 100% of the viewport width */
  height: 100vh;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(to bottom, #373737 20%, #333 40%, #202020 100%); */
  background-color: #5d5d5d;
  z-index: 2;

  background: linear-gradient(
      45deg,
      transparent 49%,
      #333 49% 51%,
      transparent 51%
    ),
    linear-gradient(-45deg, transparent 49%, #333 49% 51%, transparent 51%);
  background-size: 1em 1em;
  background-color: #222;
  opacity: 1;
}

#separator  {
  background-color: #ff8800;
  width: 100vw; /* Set the width to 100% of the viewport width */
  height: 100vh;
  z-index: 12980809;

}

.intro-bg-1 {
  box-shadow: inset 0px 1px 3px 0px #767676;
  background: linear-gradient(to bottom, #444444ae 30%, #171717ae 100%);
  background-color: #353535;
  border: 1px solid #282828;
  text-align: center;
  border-radius: 30px;
  max-width: 370px;
}

#preloader {
  text-align: center;
  transform: translate(-50%);
  position: relative;
  margin-bottom: 20px;
  left: 50%;
  width: 20vw; /* Set the width to 100% of the viewport width */
  background-color: rgba(46, 46, 46, 0);
  z-index: 3;
}

#progress-container {
  display: flex;
  align-items: center; /* Center vertically */
  height: 25px;
  margin: 0 20%;
  background: rgb(34, 34, 34);
  background: linear-gradient(
    180deg,
    rgb(25, 25, 25) 0%,
    rgb(30, 30, 30) 35%,
    rgba(34, 34, 34) 100%
  );
  border-radius: 6px;
  margin-bottom: 12px;
}

#progress-bar {
  height: 86%;
  width: 0%;
  background: linear-gradient(
    0deg,
    rgb(40, 40, 40) 0%,
    rgb(55, 55, 55) 50%,
    rgb(40, 40, 40) 100%
  );
  border-radius: 5px;
  margin-top: 4px;
  margin-bottom: 5px;
  margin-left: 2px;
  margin-right: 1px;
}

#progress-text {
  color: rgba(125, 125, 125, 0.4);
  font-size: 24px;
  font-family: "Bebas Neue", sans-serif !important;
  font-weight: 500;
  background-color: rgba(46, 46, 46, 0);
}

.logo-intro {
  width: 300px;
  height: 233px;
  margin-top: 30px;
}

.texto-intro {
  font-family: "Bebas Neue", sans-serif;
  font-size: 26px;
  line-height: 32px;
  color: #6d6d6d;
  text-align: center;
  margin: 1px 10% 20px 10%;
}

.texto-intro-sm {
  font-family: "Bebas Neue", sans-serif;
  font-size: 20px;
  color: #6d6d6d;
  text-align: center;
  margin: 0px 10% 30px 10%;
}

#intro .myButton {
  text-align: center;
}

#container {
  width: 100%;
  height: 100%;
}

#tip1 {
  text-align: center;
  position: absolute;
  bottom: -30px; /* Position the element 50% from the top of the screen */
  left: 0%; /* Position the element 50% from the left of the screen */
  transform: translate(-50%, -50%); /* Center the element using transform */
  justify-content: center;
  align-items: center;
}

#tip2 {
  text-align: center;
  position: absolute;
  bottom: -30px; /* Position the element 50% from the top of the screen */
  left: 0%; /* Position the element 50% from the left of the screen */
  transform: translate(-50%, -50%); /* Center the element using transform */
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 740px) {
  #tip1 {
display: none;
  }
  #tip2 {
    display: none;
  }

  #progress-bar {
    height: 86% !important;
  }

  .equipo {
    margin-left: -5px !important;
  }

  .ofis {
    width: 94% !important;
    margin-bottom: 30px !important;
    margin-top: 10px !important;
  }

  .mapita {
    margin-bottom: 30px !important;
  }

  #progress-container {
    height: 24px !important;
  }

  #preloader {
    width: 70vw !important;
    margin: 0px 0px 0px 24px !important;
  }

  #progress-text {
    font-size: 20px !important;
    font-family: "Bebas Neue", sans-serif !important;
    margin: 10px 50px 20px 0px !important;
  }

  #progress-container {
    margin: 0 10px !important;
  }

  .intro-bg-1 {
    max-width: 300px !important;
  }

  .logo-intro {
    width: 85% !important;
    height: auto !important;
  }

  .texto-intro {
    font-family: "Bebas Neue", sans-serif;
    font-size: 19px;
    margin: 0px 5% 10px 5%;
  }

  .oculto {
    display: none;
  }

  .marcas {
    margin-left: 30px;
  }
}

.myButton {
  box-shadow: inset 0px 1px 3px 0px #767676;
  background: linear-gradient(to bottom, #444 5%, #333 100%);
  background-color: #5d5d5d;
  border: 1px solid #282828;
  display: inline-block;
  cursor: pointer;
  color: #959595;
  font-family: "Bebas Neue", sans-serif !important;
  font-size: 34px;
  letter-spacing: 2px;
  font-weight: 800;
  padding: 10px 30px;
  text-decoration: none;
  text-shadow: 0px -1px 0px #181818;
  border-radius: 8px;
  margin-bottom: 40px;
}

.myButton:hover {
  background: linear-gradient(to bottom, #222 5%, #444 100%);
  background-color: #7b7b7b;
  color: #767676;
}

.myButton:active {
  position: relative;
  top: 1px;
}

@media only screen and (max-width: 740px) {
  #progress-container {
    width: 70%;
    height: 40px;
  }
  #progress-bar {
    height: 36px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 2px;
  }
  #progress-text {
    font-size: 30px;
  }

  #audioButton {
    top: 40px; /* Position the element 50% from the top of the screen */
    left: -24px !important;
  }

  .myButton {
    font-family: "Bebas Neue", sans-serif !important;
  }
  .custom-card {
    margin-bottom: 20px !important;
  }
  .custom-360 {
    margin-left: 0px !important;
    max-width: 80% !important;
    margin-bottom: 30px !important;
    margin-top: 30px !important;
  }

  .custom-logo {
    margin-left: 0px !important;
    max-width: 100% !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
}

.modal-fullscreen {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* background: #666666a1; */
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: linear-gradient(
      45deg,
      transparent 49%,
      #8a8a8a 49% 51%,
      transparent 51%
    ),
    linear-gradient(-45deg, transparent 49%, #8a8a8a 49% 51%, transparent 51%);
  background-size: 1em 1em;
  background-color: #f7f7f791;
  opacity: 1;
}

.modal-dialog {
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
  padding: 0;
  margin: 0;
  z-index: 9999;
}

.modal-content {
  background: #bbbbbb91;
  height: 100%;
  border: none;
  border-radius: 0;
  z-index: 9999;
}

@media only screen and (max-width: 740px) {
  .modal-fullscreen {
    width: 100%;
  }
  .modal-dialog {
    max-width: 100% !important;
  }
}

.anticlick {
  pointer-events: none;
  cursor: auto;
}

.rounded {
  border-radius: 20px !important;
}

.close {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  color: #f9f9f9;
  text-shadow: 0 1px 0 #3c3c3c;
  opacity: 0.5;
  top: 0px;
  padding: 1rem;
  z-index: 9999;
  background: linear-gradient(to bottom, #444 5%, #333 100%);
  background-color: #5d5d5d;
  border: 1px solid #282828;
  display: inline-block;
  letter-spacing: 2px;
  padding: 10px 30px;
  text-decoration: none;
  text-shadow: 0px -1px 0px #454545;
  border-radius: 8px;
  margin-bottom: 10px;
}

#servicesSection,
#portfolioSection,
#labSection,
#contactSection,
#homeSection {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(192, 192, 192, 0.426);
  font-size: 40px;
  font-family: "Bebas Neue", sans-serif;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  background: linear-gradient(to bottom, #444 5%, #333 100%);
  background-color: #5d5d5d;
  border: 1px solid #282828;
  display: inline-block;
  letter-spacing: 2px;
  padding: 10px 30px;
  text-decoration: none;
  text-shadow: 0px -1px 0px #454545;
  border-radius: 8px;
  margin-bottom: 10px;
}

.modal-header {
  text-align: center;
  position: relative;
  border-bottom: none !important;
}

.modal-header .close {
  position: absolute;
  top: 0;
  right: 50%;
  padding: 15px;
  margin: -15px;
}

/* Customize the modal scroller */
.modal-dialog.modal-dialog-scrollable {
  max-height: calc(100% - 120px); /* Adjust the height as needed */
}

.modal-body {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #666 #ccc; /* Adjust the scrollbar color as needed */
}

/* Hide the scrollbar arrows */
.modal-body::-webkit-scrollbar {
  width: 8px; /* Adjust the width as needed */
}

.modal-body::-webkit-scrollbar-track {
  background-color: #a7a7a799; /* Adjust the background color as needed */
}

.modal-body::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #444 5%, #333 100%);
  background-color: #5d5d5d;
  border: 1px solid #282828;
  border-radius: 8px;
}

#modalBackdrop {
  pointer-events: none;
  cursor: auto;
}

.object-behind-modal {
  pointer-events: none;
  cursor: auto;
}

.btn-primary:hover {
  color: #ff8800;
  background-color: #ccccccc0 !important;
  border-color: #ccccccc0 !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #ff8800;
  background-color: #ccccccc0 !important;
  border-color: #ccccccc0 !important;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 119, 152, 0.9);
  z-index: 100;
  pointer-events: none;
}

h1,
.h1 {
  font-size: 36px;
  line-height: 1.2;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 20px;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-family: "Titillium Web", sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  letter-spacing: 1px;
}

p {
  font-family: "Titillium Web", sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.nav-item a {
  color: #383838 !important;
}

.card-text {
  font-family: "Titillium Web", sans-serif !important;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1;
  color: #212529;
}

.card-header {
  padding: 0.5rem 1rem !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0) !important;
}

.ltn__card-title {
  font-family: "Titillium Web", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  line-height: 1.5;
  color: #666666 !important;
  margin-left: 10px;
  margin-top: 6px;
  text-transform: uppercase !important;
}

a:hover {
  text-decoration: none !important;
}

.btn-link {
  font-family: "Titillium Web", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  line-height: 1.5;
  color: #212529 !important;
  text-transform: uppercase !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  font-family: "Titillium Web", sans-serif !important;
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  font-size: 1rem;
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  color: #4e4e4e;
  clear: both;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
}

#whatsapp-btn {
  position: absolute;
  bottom: 100px; /* Position the element 50% from the top of the screen */
  right: 0%; /* Position the element 50% from the left of the screen */
  transform: translate(-50%, -50%); /* Center the element using transform */
  justify-content: center;
  align-items: center;
}

.whatsapp {
  width: 60px;
  height: 60px;
  box-shadow: inset 0px 1px 3px 0px #767676;
  background: linear-gradient(to bottom, #444 5%, #333 100%);
  background-color: #5d5d5d;
  border: 1px solid #282828;
  color: #fff;
  border-radius: 10px;
  font-size: 30px;
  z-index: 1;
  text-align: center;
  position: fixed;
  justify-content: center;
  align-items: center;
}

.whatsapp-icon {
  margin-top: 13px;
}

.whatsapp:hover {
  background: linear-gradient(to bottom, #222 5%, #444 100%);
  background-color: #7b7b7b;
  color: #767676;
}

#audioButton {
  position: absolute;
  top: 60px; /* Position the element 50% from the top of the screen */
  left: -16px; /* Position the element 50% from the left of the screen */
  transform: translate(-50%, -50%); /* Center the element using transform */
  justify-content: center;
  align-items: center;
  padding: 1px 1px;
  font-size: 22px;
  box-shadow: inset 0px 1px 3px 0px #767676;
  background: linear-gradient(to bottom, #444 5%, #333 100%);
  background-color: #5d5d5d;
  border: 1px solid #282828;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

#audioButton:hover {
  background: linear-gradient(to bottom, #222 5%, #444 100%);
  background-color: #7b7b7b;
  color: #767676;
}

.card {
  border: 0.01px solid rgba(255, 255, 255, 0) !important;
  border-radius: 16px !important;
}

.pull-right {
  float: right !important;
  font-size: 20px !important;
  margin-top: 1px !important;
}

.card-img,
.card-img-top {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.custom-card {
  background-color: #ffffff45;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.custom-card-s {
  background-color: #ffffff45;
  border-radius: 15px;
  padding: 1px 20px 1px 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.custom-card-s-inside {
  border-radius: 15px;
  padding: 0 10px 20px 10px;
}

.card-img-top-inside {
  margin-bottom: 20px !important;
}

.custom-card-work {
  background-color: #ffffff45;
  border-radius: 15px;
  padding: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.custom-img {
  border-radius: 20px !important;
  padding: 10px;
}

#myCarousel {
  /* Add rounded corners to the carousel */
  overflow: hidden; /* Hide any content that overflows the rounded corners */
}

/* Custom styles for carousel slides */
.carousel-item {
  /* Add rounded corners to individual slides */
  overflow: hidden; /* Hide any content that overflows the rounded corners */
}

.btn-primary {
  background: linear-gradient(to bottom, #444 5%, #333 100%);
  background-color: #5d5d5d;
  border: 1px solid #282828;
}
.btn {
  font-family: "Titillium Web", sans-serif !important;
  display: inline-block;
  font-weight: 400;
  color: #dcdcdc;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.flip-card {
  perspective: 1000px;
  width: 250px;
  height: 180px;
  border-radius: 20px !important;

  background-color: #ffffff00;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  border-radius: 20px !important;
}

.flip-card2 {
  perspective: 1000px;
  width: 180px;
  height: 150px;
  border-radius: 20px !important;

  background-color: #ffffff00;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.flip-card-inner2 {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px !important;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  border-radius: 20px !important;
  background-color: #ffffff35;
}

.flip-card-front {
  background-color: #ffffff2e;
}

.flip-card-back {
  background-color: #ffffff2e;
  color: #505050;
  transform: rotateY(180deg);
}


